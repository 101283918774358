<template>
    <OModal ref="objectsMetaUpdateDialog" >
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Objects Meta Update") }}
                        </h4>
                        <div v-if="isExecutingProc" class="spinner-border spinner-border-sm" style="0.35rem;" role="status">
                            <span class="visually-hidden">{{ $t('Processing...') }}</span>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row">                    
                    <OTabs ref="tabsRef" class="mt-2">
                        <OTab id="tab1" :title="$t('Configuration')" active>
                            <small style="font-style:italic">{{$t("Changes will only apply for selected objects against selected configurations")}}</small> 
                            <div>{{ selectedObjectsCount }} {{$t(" objects selected")}}</div>
                            
                            <ODataGrid :data-object="dsConfigurations" style="height:300px" filter-row ref="grid" hideGridMenu="true" >                                                                
                                <OColumn field="ObjectType" width="200" sortable :headerName='$t("Object Type")' :headerTitle='$t("Object Type")'  />
                                <OColumn field="CommonMetaColumnName" width="200" sortable :headerName='$t("Common Column")' :headerTitle='$t("Common Column")'  />
                                <OColumn field="UpdateColumnName" width="200" sortable :headerName='$t("Update Column")' :headerTitle='$t("Update Column")'  />
                            </ODataGrid>
                            
                            <div class="modal-footer">
                                <div style="display:inline;font-size:small;" class="me-2" v-if="selectedObjectsCount===0">
                                    <div class="text-danger">{{ $t("No objects selected") }}</div>
                                </div>
                                <button class="btn btn-outline-primary" :disabled="selectedObjectsCount===0 || isExecutingProc || selectedConfigsCount===0" @click="findObjectsUpdateChanges">{{$t("Find Changes")}}</button>
                            </div>
                        </OTab>
                        <OTab id="tab2" :title="$t('Changes')">
                            <ODataGrid :data-object="dsChanges" style="height:300px" filter-row ref="grid" hideGridMenu="true" >                                                                
                                <OColumn field="ObjectType" width="150" sortable :headerName='$t("Object Type")' :headerTitle='$t("Object Type")'  />
                                <OColumn field="Object" width="150" sortable :headerName='$t("Object")' :headerTitle='$t("Object")'  />
                                <OColumn field="MetaObjectType" width="150" sortable :headerName='$t("Meta Object Type")' :headerTitle='$t("Meta Object Type")'  />
                                <OColumn field="OldValue" width="200" sortable :headerName='$t("Old Value")' :headerTitle='$t("Old Value")'  />
                                <OColumn field="NewValue" width="200" sortable :headerName='$t("New Value")' :headerTitle='$t("New Value")'  />
                            </ODataGrid>  
                            
                            <div class="modal-footer">                                 
                                <button class="btn btn-outline-primary" :disabled="selectedObjectsCount===0 || isExecutingProc || selectedChangesCount===0" @click="CommitObjectsUpdateChanges">{{$t("Commit Changes")}}</button>
                            </div>                         
                        </OTab>
                    </OTabs>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
import { ref, defineExpose, computed, watch } from 'vue';
import OModal from 'o365.vue.components.Modal.vue'; 
import alert from 'o365.controls.alert.ts';

const procSubmit = getOrCreateProcedure({ id: "procSubmit" + crypto.randomUUID(), procedureName: "astp_Assets_ObjectsFindMetaUpdateChanges" });
const procCommitt = getOrCreateProcedure({ id: "procSubmit" + crypto.randomUUID(), procedureName: "astp_Assets_ObjectsCommittMetaUpdateChanges" });
const objectsMetaUpdateDialog = ref();
const isExecutingProc = ref(false);
const tabsRef = ref(null);

const dsConfigurations = getOrCreateDataObject({
    id: 'dsDataObject' + crypto.randomUUID(),
    viewName: 'aviw_Assets_ObjectsMetaUpdate',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
            [{name: "ID", type: "number" },
            {name: "ObjectType", type: "string" }, 
            {name: "CommonMetaColumnName", type: "string" },             
            {name: "UpdateColumnName", type: "string" }]
});

const dsChanges = getOrCreateDataObject({
    id: 'dsDataObject' + crypto.randomUUID(),
    viewName: 'aviw_Assets_ObjectsMetaUpdateChanges',
    uniqueTable: 'atbv_Assets_ObjectsMetaUpdateChanges',
    maxRecords: 25,
    whereClause: "Committed IS NULL", 
    distinctRows: false,
    allowUpdate: true,
    allowDelete: true,
    allowInsert: false,
    fields:
            [{name: "ID", type: "number" },
            {name: "OldValue", type: "string" }, 
            {name: "NewValue", type: "string" }, 
            {name: "MetaObjectType", type: "string" }, 
            {name: "Object", type: "string" },             
            {name: "ObjectType", type: "string" }]
});

async function CommitObjectsUpdateChanges() {
    const ids = dsChanges.selectionControl.selectedRows;
    const preparedRows = [];
    ids.forEach(sel => {
        if (sel.ID !== null) preparedRows.push([sel.ID]);
    });
    if (ids.length>0) {
        isExecutingProc.value = true;
        await procCommitt.execute({
            Changes : preparedRows
        }).then(
            (response) => {
                objectsMetaUpdateDialog.value.hide();
            }
        ).finally(() => {
            isExecutingProc.value = false;            
        });    
    }
}

function findObjectsUpdateChanges() {
    if (props.objectsDataObject.selectionControl.selectedRows.length>0) {
        const ids = props.objectsDataObject.selectionControl.selectedRows;
        const preparedRows = [];
        ids.forEach(sel => {
            if (sel.ID !== null) preparedRows.push([sel.ID]);
        });

        const idsConf = dsConfigurations.selectionControl.selectedRows;
        const preparedRowsConf = [];
        idsConf.forEach(sel => {
            if (sel.ID !== null) preparedRowsConf.push([sel.ID]);
        });
        findChanges(preparedRows, preparedRowsConf);
    } 
}

defineExpose({ show });

const props = defineProps({
    objectsDataObject: {
        type: Object,
        required: true
    }
});

const selectedObjectsCount = computed(() => props.objectsDataObject.selectionControl.selectedRows.length);
const selectedConfigsCount = computed(() => dsConfigurations.selectionControl.selectedRows.length);
const selectedChangesCount = computed(() => dsChanges.selectionControl.selectedRows.length);

async function show() { 
    dsConfigurations.load();
    dsChanges.load();
    objectsMetaUpdateDialog.value?.show();
}

async function findChanges(ids,confs) {
    isExecutingProc.value = true;
    await procSubmit.execute({
        Objects: ids,
        Configurations : confs
    }).then(
        (response) => {
            dsChanges.load();
        }
    ).finally(() => {
        isExecutingProc.value = false; 
        tabsRef.value.setActiveTab('tab2');      
    });    
}

</script>